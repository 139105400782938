<template>
    <div class="self-start relative">
        <p class="font-bold text-base border-b pb-2 mb-4">
            Händelser
        </p>
        <div v-for="event in eventsData" class="flex justify-start mb-4" :key="event.id">
            <div class="w-10 h-10 flex justify-center items-center text-white mr-3 bg-green-400 rounded-full">
                <i class="fa-regular fa-calendar fa-lg text-base" style="color: hsl(0, 0%, 99%);" />
            </div>
            <div class="flex-col">
                <p class="font-light">{{ event.header }}</p>
                <p v-if="event.subHeader" class="font-light my-2 text-sm break-normal">{{ event.subHeader }}</p>
                <p class="text-gray-400 text-xs">{{ eventTimeFormater(event.eventTimeUtc) }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";

export default {
    props: {
        eventsData: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        eventTimeFormater(time) {
            return moment(time).format("YYYY-MM-DD HH:mm");
        },
    },
};
</script>
